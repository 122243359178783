<template>
  <div>
    <!-- <b-tabs content-class="mt-0" ref="tabs" class="report-tabs" v-model="tabIndex" align="center">
      <b-tab title="INVENTORY REPORT" class="stock " active>
        <NewReports v-if="tabIndex == 0" />
      </b-tab>
      <b-tab title="PRODUCTS REPORT" class="listing">
        <GeneratedReports v-if="tabIndex == 1" />
      </b-tab>
    </b-tabs> -->
    <b-tabs v-if="!isGenerateReport" content-class="mt-0" ref="tabs" class="report-tabs report-page-tab" v-model="tabIndex" align="left">
      <b-tab v-for="(report,index) in allReportsType" :key="index" :title="report.report" :id="report.report" class="stock " :active="index === 0">
        <NewReports @generateReport="generateReport($event)" :reportDetails="report" />
      </b-tab>
    </b-tabs>
    <div v-if="isGenerateReport">
      <GenerateReport :selectedReport="selectedReport" @backToReport="generateReport(event)"/>
    </div>
  </div>
</template>

<script>
import NewReports from "../components/Tabs/NewReports.vue";
// import GeneratedReports from "../components/Tabs/GeneratedReports.vue";
import DatePicker from "vue2-datepicker";
import { getGeneratedReportTypes } from "../Service/ReportsService.js";
import GenerateReport from "../components/Reports/GenerateReport.vue";

export default {
    components:{
    DatePicker,
        NewReports,
        GenerateReport,
    },
    data() {
    return {
      tabIndex: 0,
      allReportsType:[],
      isGenerateReport:false,
      selectedReport:{}
    };
  },
  created(){
    this.getGeneratedReportTypes();
  },
  methods:{
    getGeneratedReportTypes() {
      getGeneratedReportTypes()
        .then((res) => {
          let resp = res.response;
          this.allReportsType = resp;
          // resp.forEach((element) => {
          //   console.log(element)
          //   this.allReportsType = element;
          //   // this.reportsTypeOptions.push({
          //   //   id: element.id,
          //   //   label: element.report,
          //   // });
          // });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    generateReport(event){
      console.log("event envent ", event)
      this.selectedReport = event
      this.isGenerateReport = !this.isGenerateReport
    }
  }
}
</script>

<style scoped>

.cards {
  margin-top: 20px;
}
</style>
<style>

.report-page-tab .nav-tabs{
  margin-left: 70px !important;
  padding: 0px !important;
}
</style>