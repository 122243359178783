import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const baseReportsUrl = process.env.VUE_APP_REPORTS_SERVER_URL;
const baseUserUrl = process.env.VUE_APP_USER_SERVER_URL;


export const getGeneratedReportsWithFilter = ( startDate, endDate, reportType, pageNumber = 0, pageSize = 2, companyId, createdBy ) => {
    let uri = `${baseReportsUrl}/api/v1/reports/companies?page-no=${pageNumber}&page-size=${pageSize}`; 
    console.log(reportType," reportType")
    if (startDate) {
        uri = uri.concat('&from-date=', startDate);
    }
    if (endDate) {
        uri = uri.concat('&to-date=', endDate);
    }
    if (reportType && reportType.length > 0) {
        uri = uri.concat('&report-type=', reportType);
    }
    if (createdBy) {
        uri = uri.concat('&created-by=', createdBy);
    }
   
    return apiHelper.get(uri);
}

export const getGeneratedReportTypes = () => {
    const uri = `${baseReportsUrl}/api/v1/report-type`;
    return apiHelper.get(uri);
}

export const downloadReport = ( reportId ) => {
    let uri = `${baseReportsUrl}/api/v1/reports/download?report-id=${reportId}`;
    // let uri = `${baseReportsUrl}/api/v1/reports/download?company-id=${companyId}`;
    // if (reportId != '') {
    //     uri = uri.concat('&report-id=',reportId);
    // }

    return apiHelper.downloadFile(uri);
}

export const generatNewReport = () => {
    const uri = `${baseReportsUrl}/api/v1/report-type`;
    return apiHelper.get(uri);
}

export const newReportGenerate = ( channelIds, reportType ) => {
    let uri = `${baseReportsUrl}/api/v1/reports/generate?`;
    if (channelIds && channelIds.length > 0) {
        uri = uri.concat('&channel-ids=', channelIds);
    }
    if (reportType) {
        uri = uri.concat('&report-type=', reportType);
    }
    
    return apiHelper.get(uri);
}

// for generated dropdown for user list
export const getGenerateList = () => {
    const uri = `${baseUserUrl}/v1/users`;

    return apiHelper.get(uri);
}
