<template>
  <div class="new-reports" style="margin-bottom: 20px">
    <b-card class="m-2 mt-4 mb-0" :body-class="'card-padding'" v-if="isReportFilter">
      <img class="mt-3 close-filter-icon" src="../../assets/CloseModel.svg" width="15px" style="cursor:pointer" @click="openDesktop_filterEvent">
      <div class="row">
        <div class="col-md-3">
          <label class="tree-label">Generated Date</label>
          <date-picker
            v-model="selectedDurationDate"
            id="generate-date"
            :lang="'en'"
            range
            confirm
            :shortcuts="[]"
            @clear="clearDurationDate($event)"
            @confirm="getDurationDate($event)"
          ></date-picker>
        </div>
        <div class="col-md-3 filters">
          <label class="tree-label">Generated By</label>
          <w-dropdown
            :label-text="''"
            :labelId="'generated-by'"
            :selectedValue="null"
            :selectOption="generateListOptions"
            @selectedOption="getSelectedGenerateList($event)"
          />
        </div>
      </div>
    </b-card>

    <!-- <b-card class="cards m-2 generate_reporttable"> -->
    <w-table
      :class="isReportFilter?'mt-3':'mt-5'"
      :isFilterButton="true"
      :tableProperties="tableProperties"
      @downloadReport="downloadReport($event)"
      :tableLoading="tableLoading"
      @getSelectedPageEvent="getSelectedPage($event)"
      @getSelectedPerPageEvent="getSelectedPerPage($event)"
      @generateReport="generateReport"
      @openDesktop_filterEvent="openDesktop_filterEvent"
      :shipmentType="'genreateReportButton'"
    />
    <!-- </b-card> -->
    <!-- <b-card class="m-2 create_reportTable">
      <w-table :tableProperties="productReportTableData" @generateEvent="generateEvent($event)" :tableLoading="tableLoading" />
    </b-card> -->

 <!-- modal for error handling -->

    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

    <w-loading-spinner :loadSpinner="loadingWidget" :isSchedulePickup="true" />

  </div>
</template>

<script>
import moment from "moment";
import moment_timezone from "moment-timezone";
import DatePicker from "vue2-datepicker";
import Dropdown from "../../widgets/Dropdown.vue";
import TreeDropdown from "../../widgets/TreeDropdown";
import TableWithCheckbox from "../../widgets/TableWithCheckbox.vue";
import Modal from "../../widgets/ModalWidget.vue";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import { getGenerateList, newReportGenerate,getGeneratedReportsWithFilter,downloadReport } from "../../Service/ReportsService";

export default {
  props:["reportDetails"],
  components: {
    DatePicker,
    Modal,
    "w-loading-spinner": LoadingSpinner,
    "w-table": TableWithCheckbox,
    "w-dropdown": Dropdown,
    "w-tree-dropdown": TreeDropdown,
  },
  data() {
    return {
  // for error modal
  
      companyID:sessionStorage.getItem("companyIds"),
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
      loadingWidget: false,
      tableLoading: false,

      selectedDurationDate: "",
      tableProperties: {
        items: [],
        fields: [
          {
            key: "reportId",
            label: "Report ID",
          },
          {
            key: "generatedDate",
            label: "Generated Date",
          },
          // {
          //   key: "reportType",
          //   label: "Report Type",
          // },
          {
            key: "generatedBy",
            label: "Generated By",
          },
          // {
          //   key: "period",
          //   label: "Period",
          // },
          // {
          //   key: "fromDate",
          //   label: "From",
          // },
          // {
          //   key: "toDate",
          //   label: "To",
          // },
          {
            key: "size",
            label: "Size",
          },
          {
            key: "action_generate_field",
            label: "Action",
          },
        ],
        perPage: 50,
        currentPage: 1,
        totalRows: 0,
      },
      isReportFilter:false,
      generateListOptions:[],
      selectedgenerateValue:""
    };
  },

  created() {
   this.getGeneratedReportTypes();
   this.getGenerateList();
  },

  methods: {

    getGeneratedReportTypes() {
      this.tableLoading = true;
      this.tableProperties.items = []
      var time = new Date();
      var timeZoneOffset = time.getTimezoneOffset();
      var timeZone = moment_timezone.tz.guess();
      timeZone = moment_timezone.tz.zone(timeZone).abbr(timeZoneOffset)
      // getGeneratedReportTypes()
      //   .then((res) => {
      //     this.tableLoading = false;
      //     let resp = res.response || [];
      //     resp.forEach((data) => {
      //       if( data.report === "Inventory Report"){
      //           this.inventoryReportTableData.items.push(data)
      //       }else{
      //         this.productReportTableData.items.push(data)
      //       }
      //     })
      //       // this.inventoryReportTableData.items = resp;
      //   })
      //   .catch((err) => {
      //     this.tableLoading = false;
      //     this.alert = true;
      //     this.alertMsg = err.message;
      //     this.alertType = "Failure";
      //     this.isConfirmAlert = false;
      //   });
       getGeneratedReportsWithFilter(
          this.startDate,
          this.endDate,
          this.reportDetails.id,
          this.tableProperties.currentPage - 1,
          this.tableProperties.perPage,
          this.companyID,
          this.selectedgenerateValue
        )
          .then((res) => {
            this.tableLoading = false;
            if (!res.errorCode) {
              let resp = res.response;
              resp.content.map(item=>{
                if(item.reportType === this.reportDetails.report){
                  item.generatedDate = item.generatedDate ? `${this.getDateFormat(item.generatedDate)}` :"-";
                  // item.period = item.fromDate + " to " + item.toDate
                  this.tableProperties.items.push(item);
                }
              })
              // this.tableProperties.items = resp.content || [];
              this.tableProperties.totalRows = res.response.totalElements;
            } else {
              this.alert = true;
              this.alertMsg = res.failureMessage;
              this.alertType = "Failure";
              this.isConfirmAlert = false;
            }
          })
          .catch((err) => {
            this.tableLoading = false;
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
    },
    getDateFormat(dateAndTime){
      let time = moment.utc(dateAndTime,'YYYY-MM-DD hh:mm:ss').local().format('DD MMM, YYYY');
      return time;
    },
    getGenerateList(){
      this.generateListOptions = [];
      let generateList = [];

      this.generateListOptions.push({
        text:"Select",
        value:null
      })

      getGenerateList()
      .then((res) => {
        generateList= res;

        generateList.forEach((el) => {
          this.generateListOptions.push({
            text:el.email,
            value:el.email
          })
        })
        
      })
      .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getSelectedGenerateList(event) {
      this.selectedgenerateValue = event;
      this.getGeneratedReportTypes();
    },
    downloadReport(reportId) {
      this.loadingWidget = true;
      downloadReport(reportId)
        .then((res) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = "Downloaded Successfully";
          this.alertType = "Success";
          this.isConfirmAlert = false;
        })
        .catch((err) => {
          var self = this;
    // for read the blob response type 
          var myReader = new FileReader();
          myReader.addEventListener("loadend", function(e) {
            self.alertMsg = JSON.parse(e.srcElement.result).message;
          });
          //start the reading process.
          myReader.readAsText(err);
          this.loadingWidget = false;
          this.alert = true;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    
    // for pagination
    getSelectedPage(currentPage) {
      this.tableProperties.currentPage = currentPage;
      this.getGeneratedReportTypes();
    },

    getSelectedPerPage(perPage) {
      this.tableProperties.perPage = perPage;
      this.tableProperties.currentPage = 1;
      this.getGeneratedReportTypes();
    },
    generateEvent(reportId){
      this.loadingWidget = true;
      newReportGenerate( this.startDate, this.endDate, reportId )
        .then((res) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = res.status == "FAILURE" ? res.failureMessage : res.response ;
          this.alertType = res.status == "FAILURE" ? "Failure" : "Success";
          this.isConfirmAlert = false;
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.failureMessage;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    clearDurationDate() {
      this.startDate = "";
      this.endDate = "";
      this.getGeneratedReportTypes();
    },
    getDurationDate() {
      this.startDate = moment(this.selectedDurationDate[0]).valueOf();
      this.endDate = moment(this.selectedDurationDate[1]).valueOf();
      this.getGeneratedReportTypes();
    },

  // close the error modal

    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    generateReport(){
      this.$emit("generateReport",this.reportDetails)
    },
    openDesktop_filterEvent(){
      this.isReportFilter = !this.isReportFilter
    }
  }

};
</script>

<style scoped>
/* .card-padding {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0 !important;
} */
.newReport_label {
  font-family: ProximaNova;
  font-size: 16px;
  color: #767676;
  font-weight: 600;
}
.create_reportTable ::v-deep .table thead th:nth-child(1) {
  width: 30%;
  text-align: left;
}
.create_reportTable ::v-deep .table thead th:nth-child(2) {
  width: 50%;
}
.create_reportTable ::v-deep .table thead th:nth-child(3) {
  width: 20%;
}

.create_reportTable ::v-deep .table thead th {
  /* width: 33.33%; */
  color: #1b1c1d;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  padding-left: 10px;
  text-align: center;
}
.create_reportTable ::v-deep .table tbody td {
  /* width: 33.33%; */
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  color: #1b1c1d;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.create_reportTable ::v-deep .table tbody td button {
  color: #2e8cd3;
  background: #fff;
  border-color: #2e8cd3;
  font-size: 13px;
  padding: 2px 10px;
  border-radius: unset;
}
.create_reportTable ::v-deep .table tbody td:nth-child(1){
  text-align: left;
}

/* for responsive */
@media only screen and (max-width: 768px){
  .new-reports ::v-deep .table-pagination{
    justify-content: flex-end !important;
  }
  .new-reports ::v-deep .table-pagination select{
    margin: 0 10px;
  }
  .new-reports ::v-deep .mx-range-wrapper {
    width: 100%;
  }
  .new-reports ::v-deep .mx-calendar {
    width: 100%;
  }
  .new-reports ::v-deep .mx-calendar-content {
    width: 100%;
  }
  .new-reports ::v-deep .mx-datepicker-popup {
    left: 0px !important;
  }
}

@media only screen and (max-width:400px){
  .new-reports ::v-deep .generate-report{
    font-size: 11px;
    white-space: nowrap;
  }
  .new-reports ::v-deep .table-pagination select{
    margin: 0;
  }
}
@media only screen and (min-width: 340px) and (max-width: 390px){
  .new-reports ::v-deep .table-pagination{
    margin-left: 20px;
  }
}
@media only screen and (max-width: 340px){
  .new-reports ::v-deep .table-pagination .page-link{
    min-width: 18px !important;
  }
}
</style>