<template>
  <div class="genreate-report-wrapper whithout-tab-content">
    <div class="row p-0 m-0 w-100 my-4">
      <div class="col-6 p-0 m-0 generate-title">
        Generate New {{ selectedReport.report }}
      </div>
      <div class="col-3 p-0 m-0 ml-auto back-button" @click="backToReport">
        <img src="../../assets/SVG/Back.svg" width="60px" />
      </div>
    </div>
    <div class="row p-0 m-0">
      <div class="col-12 m-0 generate-report-form-wrapper">
        <!-- <div class="row"> -->
        <!-- <div class="col-6 p-0 m-0"> -->
        <div class="col-md-4 generate-report-date-picker">
          <!-- <p class="ml-2 generated-date">Channels</p> -->
          <!-- <date-picker
            v-model="selectedGenerateReportDate"
            class="ml-2 w-25"
            :lang="'en'"
            range
            :format="'DD/MM/YYYY'"
            confirm
            @clear="clearDurationDate($event)"
            @confirm="getDurationDate($event)"
          ></date-picker> -->

          <w-tree-dropdown
            :labelText="'Channels'"
            :rootOptions="channelListOptions"
            :selectedValue="channelListValues"
            @selectedField="selectedChannelsCategory($event)"
        />
        </div>
        <div>
          <label class="report-note my-5"
            >{{ selectedReport.report }} -
            {{ selectedReport.description }}</label
          >
        </div>
        <div>
          <w-button-input
            :label="'Generate'"
            :buttonStyle="'generate-btn'"
            :buttonClass="'generate-btn'"
            @buttonClicked="newReportGenerate"
          />
          <w-button-input
            :label="'Clear Filters'"
            :buttonStyle="'generate-clear'"
            :buttonClass="'generate-btn'"
            @buttonClicked="clearFilters"
          />
        </div>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>

    <w-loading-spinner :loadSpinner="loadingWidget" :isSchedulePickup="true" />

    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
    />
  </div>
</template>

<script>
// import moment from "moment";
// import DatePicker from "vue2-datepicker";
import ButtonInput from "../../widgets/InputButton.vue";
import Modal from "../../widgets/ModalWidget.vue";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import TreeDropdown from "../../widgets/TreeDropdown";
import { newReportGenerate } from "../../Service/ReportsService";
import { getChannelList } from "../../Service/CommonService";

export default {
  props: ["selectedReport"],
  data() {
    return {
    //   startDate: "",
      loadingWidget: false,
    //   endDate: "",
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,
    //   selectedGenerateReportDate: "",
      channelListOptions: [],
      channelListValues: [],
    };
  },

  created(){
      this.getChannelList();
  },

  methods: {
    backToReport() {
      let data = {};
      this.$emit("backToReport", data);
    },
    // getDurationDate() {
    //   this.startDate = moment(this.selectedGenerateReportDate[0]).valueOf();
    //   this.endDate = moment(this.selectedGenerateReportDate[1]).valueOf();
    // },
    // clearDurationDate() {
    //   this.startDate = "";
    //   this.endDate = "";
    // },
    clearFilters() {
      this.channelListValues = [];
    },

    newReportGenerate() {
      let reportId = this.selectedReport.id;
      this.loadingWidget = true;
      newReportGenerate(this.channelListValues, reportId)
        .then((res) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg =
            res.status == "FAILURE" ? res.failureMessage : res.response;
          this.alertType = res.status == "FAILURE" ? "Failure" : "Success";
          this.isConfirmAlert = false;
          this.channelListValues = [];
        })
        .catch((err) => {
          this.loadingWidget = false;
          this.alert = true;
          this.alertMsg = err.failureMessage;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    
     getChannelList() {
      getChannelList()
        .then((res) => {
          res.forEach((element) => {
            this.channelListOptions.push({
              id: element.channelId,
              label: element.channelName,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    selectedChannelsCategory(event) {
        this.channelListValues = event;
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
  components: {
    Modal,
    // DatePicker,
    "w-button-input": ButtonInput,
    "w-loading-spinner": LoadingSpinner,
    "w-tree-dropdown": TreeDropdown,
  },
};
</script>

<style scoped>
.genreate-report-wrapper {
  padding: 0rem 4.7rem;
}
.generate-title {
  color: #1b1c1d;
  font-size: 18px;
  font-weight: 600;
}
.back-button {
  text-align: right;
  color: #2e8cd3;
  font-size: 14px;
  cursor: pointer;
}
.generated-date {
  color: #606060;
  font-size: 12px;
}
.generate-report-form-wrapper {
  background-color: #ffffff;
  padding: 2rem !important;
  box-shadow: 0px 1px 3px #00000029;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.report-note {
  color: #1b1c1d;
  font-size: 13px;
  margin-left: 10px;
}
</style>
<style>
/* .generate-report-date-picker > .mx-datepicker-range > .mx-input-wrapper > .mx-input{
     border:1px solid #D9D9D9;
 } */
</style>
